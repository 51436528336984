
.stations-container {
	margin-bottom: 30px;
	padding-bottom: 30px;
	border-bottom: 1px solid @black-20;
}

ul.stations-list {
	li {
		margin-top: 0;
	}

	@media @screen-sm-min {
		column-count: 2;
		column-width: 50%;
		column-gap: 20px;
	}

	@media @screen-lg-min {
		column-count: 3;
		column-width: 33%;
	}
}