.dci-widget {
  padding: 20px 0 20px 0;
  border-top: #e8e9ec 2px solid;
}

.dci-widget__content {
  margin: 0 0 15px 0;
  padding: 0 115px 0 0;
  position: relative;
}

.dci-widget__content img {
  position: absolute;
  bottom: 10px;
  right: 0;
  width: auto;
}

.dci-widget__score-bar {
  width: 100%;
  height: 10px;
  background: #e8e9ec;
  margin: 0 auto;
  border-radius: 5px;
  position: relative;
}

.dci-widget__score-bar__progress {
  width: 0%;
  height: 10px;
  border-radius: 5px 0 0 5px;
  position: absolute;
  top: 0;
  left: 0;
  background: #141155;
  background: -moz-linear-gradient(left, #141155 0%, #00a5ae 100%);
  background: -webkit-linear-gradient(left, #141155 0%, #00a5ae 100%);
  background: linear-gradient(to right, #141155 0%, #00a5ae 100%);
}

.dci-widget__score-bar__score {
  width: 50px;
  line-height: 1.25;
  position: absolute;
  top: 17px;
  left: 75%;
  z-index: 99;
  margin: 0 0 0 -20px;
  background: #141155;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  border-radius: 3px;
  padding: 5px 8px;
  text-align: center;
}

.dci-widget__score-bar__score:after {
  content: "";
  display: block;
  position: absolute;
  top: -12px;
  left: 50%;
  margin: 0 0 0 -6px;
  border: transparent 6px solid;
  border-bottom-color: #141155;
}
