.rcg-tranfer-scrollbars {
    scrollbar-width: thin;
    scrollbar-color: @black-30 @black-4;

    &::-webkit-scrollbar {
        width: 11px;
    }

    &::-webkit-scrollbar-track {
        background:  @black-4;
    }

    &::-webkit-scrollbar-thumb {
        background-color: @black-30;
        border-radius: 6px;
        border: 3px solid  @black-4;
    }
}

.content {
    .rcg-transfer {
        &__header {
            position: relative;
            z-index: 2;
        }

        &__title {
            display: block;
            min-height: 65px;
            margin-top: 0;

            color: @black;
            font-family: @font-default;
            font-size: 25px;
            font-weight: normal;
            text-transform: uppercase;

            @media @medium-devices-and-smaller {
                min-width: none;
                max-width: none;
            }

            &--initial {
                padding: 2px 0 2px 14px;

                border-left: 1px solid currentColor;

                color: @black-55;
                line-height: 60px;
                text-transform: none;
            }

            &-meta {
                display: block;
                margin-top: 8px;
                padding-top: 8px;

                border-top: 1px solid currentColor;

                font-family: @font-bold;
                font-size: 14px;
                font-weight: normal;
                text-transform: none;
            }

            &-meta-type {
                font-family: @font-default;
            }
        }

        &__form {
            position: relative;

            display: flex;
            margin-bottom: 15px;
            padding-right: 262px;

            @media @medium-devices-and-smaller {
                position: static;

                display: block;
                margin-bottom: 20px;
                padding: 0;
                max-width: none;
            }

            .oebb-input {
                width: 100%;
                min-width: 200px;
                padding-left: 60px;

                font-size: 14px;
            }
        }

        &__form-togglers {
            display: none;

            @media @medium-devices-and-smaller {
                position: relative;
                z-index: 10;

                display: flex;
                justify-content: space-between;
                padding: 0 30px;

                box-shadow: 0 3px 6px rgba(0,0,0,.29);
                background: @oebb-red;

                color: white;
                text-align: center;
            }

            .rcg-transfer-icon {
                display: block;
                margin: 0 auto 6px;
            }
        }

        &__form-toggler {
            background: transparent;
            border: none;
            width: ~'calc(50% - 3.5px)';
            padding: 12px 0;
            font-size: 14px;
            text-transform: uppercase;
            border-bottom: 2px solid transparent;

            &--active {
                border-color: white;
            }
        }

        &__form-container {
            flex-grow: 1;
            max-width: 250px;
            margin-right: 15px;

            @media @medium-devices-and-smaller {
                max-width: none;
                height: 0;
                margin-right: 0;
                overflow: hidden;

                transform: translateX(120%);
                transition: transform .5s ease;

                &--active {
                    height: auto;
                    overflow: visible;

                    transform: translateX(0%);
                    transition: transform .5s ease;
                }
            }
        }

        &__form-container--from,
        &__form-container--to {
            position: relative;

            > div {
                position: relative;

                > .rcg-transfer-icon {
                    position: absolute;
                    top: 50%;
                    left: 24px;
                    z-index: 1;

                    width: 12px;
                    margin: 0;
                    fill: @black-55;

                    transform: translateY(-50%);
                }
            }
        }

        &__form-container--from {
            z-index: 2;

            @media @medium-devices-and-smaller {
                margin-bottom: 10px;
            }
        }

        &__form-container--to {
            z-index: 1;
        }

        &__form-container--to > div > .rcg-transfer-icon {
            left: 4px;
            width: 50px;
        }

        &__form-container--charge {
            position: relative;

            .oebb-input,
            .rcg-transfer-charge-button {
                display: flex;
                align-items: center;
                padding-left: 14px;

                text-align: left;
                color: @black-55;
                fill: @black-55;
                // fill: currentColor;

                &::before {
                    content: none;
                }

                & > .ic-arrow2-down,
                & > .ic-arrow2-up {
                    display: block;
                    margin-left: auto;

                    color: @black;
                }

                & > .ic-arrow2-up {
                    display: none;
                }

                &--open {
                    & > .ic-arrow2-down {
                        display: none;
                    }

                    & > .ic-arrow2-up {
                        display: block;
                    }
                }

                &--active {
                    color: @black;
                }

                & > span {
                    display: inline-block;
                    max-width: 65%;
                    overflow-x: hidden;

                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                svg:last-of-type {
                    margin-right: 10px;
                }

                .rcg-transfer-icon {
                    margin-right: 4px;
                    margin-left: 0;
                }
            }
        }

        &__form-container--from,
        &__form-container--to,
        &__form-container--charge {
            &:hover {
                &::before,
                &::after {
                    position: absolute;
                    left: 50%;

                    display: block;

                    transform: translateX(-50%);
                }

                &::before {
                    bottom: 90%;
                    z-index: 1;

                    content: attr(data-title);
                    max-width: 280px;
                    padding: 4px 8px;

                    background: @white;
                    border: 1px solid @black-55;
                    border-radius: 3px;
                    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);

                    color: @black;
                    font-size: 14px;
                }

                &::after {
                    bottom: ~'calc(100% - 11px)';
                    z-index: 2;

                    content: '';
                    width: 10px;
                    height: 10px;

                    background: @white;
                    border: 1px solid @black-55;
                    transform: translateX(-50%) rotate(45deg);
                    clip-path: inset(2px 0 0 2px);
                }
            }
        }

        &__form-container--from {
            &:hover::before,
            &:hover::after {
                left: 70px;
            }
        }

        &__form-container--to {
            &:hover::before,
            &:hover::after {
                left: 80px;
            }
        }

		.rcg-transfer-charge-list-container {
			position: absolute;

            display: none;
            width: 100%;
            margin: 0;
            padding: 4px 10px 10px;

            background-color: @black-5;
            border: 1px solid @black-55;

            font-size: 14px;

			&--open {
                display: block;
            }

            button {
                margin: 0;
                padding: 8px;

                font-size: 14px;
                font-weight: bold;

                &[hidden] {
                    display: none;
                }
            }
		}

        .rcg-transfer-charge-list {
            margin: 0 0 12px;
			padding: 0;

            list-style: none;

            li {
                position: relative;
                margin: 0;
                padding-left: 35px;

                &:last-child {
                    margin-bottom: 0;
                }

                .rcg-transfer-icon {
                    position: absolute;
                    top: 50%;
                    left: 0;

                    transform: translateY(-50%);
                }
            }

            input[type="checkbox"] {
                & + label {
                    display: block;
                    margin-left: 25px;
                    padding: 8px 0;

                    &::before {
                        left: -25px;
                    }
                }

                &:checked + label {
                    &::after {
                        left: -25px;

                        background-color: @oebb-red;
                        color: @white;
                    }
                }

                &:disabled + label {
                    opacity: 0.3;
                    cursor: default;
                    pointer-events: none;
                }
            }
        }

        .rcg-transfer-charge-filter-no-result {
            position: absolute;
            top: calc(~'100% + 50px');
            left: 80px;

            display: flex;
            align-items: center;
            padding: 15px;

            background-color: @black-4;
            border: 1px solid @black-30;
            box-shadow: 1px 1px 3px @black-30;

            font-size: 14px;

            &[hidden] {
                display: none;
            }

            & > :last-child {
                margin-left: 15px;
            }

            & > p {
                margin: 0;
            }

            & > button {
                padding: 6px;

                background: @black-10;
                border: 1px solid @black-40;
                border-radius: 0;
            }
        }

        &__form-container--transfer {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;

            max-width: 248px;
            margin-right: 0;

            @media @medium-devices-and-smaller {
                position: relative;
                top: -10px;

                max-width: none;
            }

            .oebb-input {
                padding: 10px 12px;
                color: @oebb-red;
                font-family: @font-bold;
            }
        }

        &__dropdown {
            background-color: @black-4;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                padding: 0;
            }
        }

        &__dropdown-parent {
            position: relative;

            display: flex;
            align-items: center;
            padding: 10px 18px 10px 10px;

            color: #000;
            font-family: @font-bold;
            font-size: 14px;
            line-height: 18px;
            text-decoration: none;

            &:hover,
            &:focus {
                background: @oebb-red;
                color: #fff;
                fill: #fff;
            }

            .rcg-transfer-icon {
                margin-right: 12px;
            }

            .ic-arrow2-down,
            .ic-arrow2-up {
                margin-left: auto;
                transform: translateX(4px);
            }

            .ic-arrow2-down {
                display: block;
            }

            .ic-arrow2-up {
                display: none;
            }
        }

        &__dropdown-count {
            float: right;
        }

        .open .rcg-transfer__dropdown-parent {
            background: @oebb-red;
            color: #fff;

            .rcg-transfer-icon {
                fill: #fff;
            }

            .ic-arrow2-down {
                display: none;
            }

            .ic-arrow2-up {
                display: block;
            }
        }

        &__subdropdown {
            list-style: none;
            padding: 0;
            margin: 2px 0;
            overflow-y: auto;
            max-height: 0;
            transition: max-height .5s ease;
        }

        .open .rcg-transfer__subdropdown {
            max-height: 325px;
            border-bottom: 1px solid @black-55;

            .rcg-tranfer-scrollbars();

            @media @medium-devices-and-smaller {
                max-height: none;
            }
        }

        &__dropdown-child {
            font-family: @font-default;
            color: #000;
            font-size: 14px;
            line-height: 18px;
            padding: 8px 18px 8px 47px;
            text-decoration: none;
            display: block;

            &:hover,
            &:focus,
            &--active {
                color: @oebb-red;
            }
        }

        &__content {
            position: relative;
            z-index: 10;

            display: flex;
            width: 100%;
            margin-top: -45px;
            margin-right: 20px;

            font-family: @font-default;
            font-size: 14px;
            line-height: 20px;

            @media @medium-devices-and-smaller {
                z-index: 0;

                margin-top: 0;
                margin-right: 0;
            }

            p {
                margin-bottom: 20px;
            }

            .md-button {
                margin: 10px 0 0;
            }
        }

        &-content-intro {
            margin-top: 60px;
            margin-right: 20px;

            img {
                display: block;
                width: auto;
                max-width: 100%;
            }

            &:not([hidden]) ~ .rcg-transfer-content-column {
                display: none;
            }
        }

        &-content-column {
            display: block;
            width: 100%;
            margin-right: 20px;

            &[hidden] {
                display: none;
            }

            @media @medium-devices-and-smaller {
                width: 100%;
                margin-right: 0;
            }
        }

        &-content-section {
            display: flex;
            margin-bottom: 20px;

            @media @only-small-devices-for-grid {
                flex-direction: column;
            }
        }

        &-content__main,
        &-content__meta {
            padding: 20px 20px;
            background-color: @black-4;
        }

        &-content__main {
            width: 100%;
            padding-top: 20px;

            @media @only-small-devices-for-grid {
                width: 100%;
                margin-bottom: 5px;
            }
        }

        &-content__meta {
            flex: 0 0 auto;
            width: 290px;
            margin-left: 20px;

            @media @only-small-devices-for-grid {
                width: 100%;
                margin-left: 0;
            }

            dl {
                margin: 15px 0 10px;
            }

            dt,
            dd {
                display: inline;
                margin: 0;
            }
        }

        &__transfer-title {
            font-family: @font-default;
            font-size: 25px;
            font-weight: normal;
            line-height: 29px;
            text-transform: uppercase;
            margin: 0 0 13px;

            &--delimiter {
                display: inline-block;
                vertical-align: middle;
                color: @oebb-red;
            }
        }

        &__transfer-subtitle {
            font-family: @font-bold;
            font-size: 14px;
            font-weight: normal;
            line-height: 18px;
            margin: 0 0 20px;
        }

        &__transfer-combinaion-route {
            margin-top: -15px;
        }

        &__transfer-headline {
            font-family: @font-bold;
            font-weight: normal;
            font-size: 10px;
            line-height: 13px;
            text-transform: uppercase;
            margin: 30px 0 14px;

            &:first-child {
                margin-top: 0;
            }
        }

        &__list {
            margin: 0 0 20px;
            padding-left: 20px;

            li {
                margin: 0;
            }
        }

        &__txt-highlight {
            font-family: @font-bold;
        }

        &__legend {
            flex: 0 0 auto;
            align-self: flex-start;
            width: 290px;
            margin-left: auto;
            padding: 20px 14px 20px 20px;

            background: #fff;
            border-top: 2px solid @oebb-red;
            border-bottom: 2px solid @oebb-red;
            box-shadow: 0 3px 6px rgba(0,0,0,.29);

            @media @medium-devices-and-smaller {
                display: none;
            }
        }

        &-legend__list {
            margin-left: 0;
            margin-bottom: 15px;
            padding: 0;
            list-style: none;

            li {
                margin: 4px 0;
            }
        }

        &-icon {
            display: inline-block;
            width: 25px;
            height: 25px;
            margin-right: 35px;
            vertical-align: middle;
            pointer-events: none;
        }

        &-location-list {
            margin-top: -5px;
            margin-left: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;

            li {
                width: ~'calc(50% - 20px)';
                margin-right: 20px;

                white-space: nowrap;
            }

            .rcg-transfer-icon {
                margin: 0 0 0 4px;
            }
        }

            // input[type="checkbox"] {
            //     display: inline-block;
            //     vertical-align: middle;
            // }

        input[type="checkbox"] {
            position: absolute;

            width: 0.1px;
            height: 0.1px;

            appearance: none;
            opacity: 0;

            & + label {
                position: relative;

                margin-left: 22px;

                cursor: pointer;
            }

            & + label::before,
            &:checked + label::after {
                position: absolute;
                top: 50%;
                left: -20px;

                content: '';
                display: inline-block;
                width: 15px;
                height: 15px;

                background-color: @white;
                border: 1px solid @black;
                border-radius: 3px;
                transform: translateY(-50%);

                text-align: center;
            }

            &:checked + label::after {
                content: '\e629';
                background-color: @white;

                color: @black;
                font-family: "oebb-fonticons";
                line-height: 1;
            }

            &:focus + label::before,
            &:active + label::before {
                outline: 2px dotted @oebb-red;
                outline-offset: 2px;
            }
        }

        #rcg-transfer-location-terminal:checked + label::after {
            background-color: @oebb-red;
            color: @white;
        }

        #rcg-transfer-location-habor:checked + label::after {
            background-color: @blue-549;
            color: @black;
        }

        #rcg-transfer-location-warehouse:checked + label::after {
            background-color: @gray-white;
            color: @black;
        }

        &-transnet-filter,
        &-transnet-filter + label {
            vertical-align: middle;
        }

        &-transnet-filter + label {
            margin-left: 6px;
        }

        .oebb-autocomplete-list {
            margin: 0;
            padding: 10px 0;
            border: 1px solid #707070;
            background: white;
            box-shadow: 0 3px 6px rgba(0,0,0,.31);
            list-style: none;

            .rcg-tranfer-scrollbars();
        }

        .oebb-autocomplete-item {
            position: relative;
            border-bottom: 1px solid #a4b0b8;
            margin: 0;
            padding: 10px 10px;
            font-size: 14px;
            line-height: 18px;
            height: auto;

            &:last-of-type {
                margin-top: 2px;

                border-top: 1px solid #a4b0b8;
                border-bottom: none;
            }

            .rcg-transfer-icon {
                margin: 0 10px 0 0;
            }
        }

        // &-location-marker {
        //     // position: relative;

        //     height: 0; // Avoid a "ghost" clickable area
        //     border: 1px solid transparent;

        //     &::before,
        //     &::after {
        //         position: absolute;
        //         left: -6px;

        //         content: '';
        //         display: block;

        //         border: 6px solid transparent;
        //     }

        //     &::before {
        //         bottom: -6px;

        //         border-top-color: @black-55;
        //     }

        //     &::after {
        //         bottom: -5px;

        //         border-top-color: @white;
        //     }
        // }

        &-location-popup {
            .leaflet-popup-content-wrapper {
                border: 1px solid @black-55;
                border-radius: 3px;
            }

            .leaflet-popup-content {
                margin: 4px;

                text-align: center;
            }

            .leaflet-popup-close-button {
               display: none;
            }

            .leaflet-popup-tip-container {
                transform: translateY(-1px);
            }

            .leaflet-popup-tip {
                width: 12px;
                height: 12px;
                margin-top: -7px;
            }
        }

        &-location-marker__content {
            // position: relative;

            // margin-top: -6px;
            // margin-left: -15px;
            // padding: 2px;

            // background-color: @white;
            // border: 1px solid @black-55;
            // border-radius: 3px;
            // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4); // Leaflet Popup
            // transform: translateY(-100%); // Icon from the bottom

            text-align: center;
        }

        .rcg-transfer-location-marker-link {
            display: block;
            width: 25px;
            height: 25px;
            margin: 0 auto;

            background-image: url('../../images/rcg-transfer/locations.svg');
            background-position: 0 0;
            background-repeat: no-repeat;

            &--terminal {
                background-position: 0 0;
            }

            &--habor {
                background-position: -34px 0;
            }

            &--station {
                background-position: -66px 0;
            }

            &--warehouse {
                background-position: -98px 0;
            }
        }

        &-location-marker__content--more {
            .rcg-transfer-location-marker-link {
                display: block;
                height: 0;

                opacity: 0;
                transition: height 0.5s ease, opacity 0.5s ease;
            }

            &:hover .rcg-transfer-location-marker-link {
                height: 25px;

                opacity: 1;
                transition: height 0.5s ease, opacity 0.5s ease;
            }

            .rcg-transfer-location-marker__count {
                display: block;
                height: 25px;

                background-color: @white;
                cursor: default;

                font-size: 14px;
                font-family: @font-bold;
                line-height: 25px;
            }

            &:hover .rcg-transfer-location-marker__count {
                color: @black-70;
            }
        }

        &__contact-us {
            display: block;
            width: ~'calc(100% - 310px)';
            margin-right: 20px;
            padding: 20px 60px 20px 20px;

            background-color: @black-4;

            @media @medium-devices-and-smaller {
                width: 100%;
                margin-right: 0;
                padding: 20px;
            }

            &[hidden] {
                display: none;
            }

            &-title {
                font-size: 1.75em;
            }

            &-text {
                font-size: 1.25em;
            }

            h2 {
                margin-bottom: 0.83em;
            }
        }

        &__emission-chart {
            width: 90%;
            height: auto;
            margin: 0 auto;

            .lines {
                stroke: #222222;
                font-size: 3rem;
                font-family: sans-serif;

                text {
                    text-anchor: end;
                    dominant-baseline: mathematical;
                }
            }
        }
    }
}

#rcg-transfer-map {
    position: relative;
    // top: 5px;
    z-index: 1;

    height: 635px;

    background-color: #f9f9f9;

    @media @medium-devices-and-smaller {
        height: 250px;
        margin-bottom: 30px;
    }

    .leaflet-control-attribution {
        transform: translate(3px, -50px);

        @media @medium-devices-and-smaller {
            transform: none;
        }
    }

    .leaflet-control-zoom {
        border: none;
    }

    .leaflet-control-zoom a,
    .leaflet-control-zoom a:hover {
        width: 26px;
        height: 26px;
        box-sizing: content-box;

        background-color: @white;
        border: 2px solid @oebb-red;
        border-radius: 50%;

        color: @oebb-red;
        line-height: 26px;
    }

    .leaflet-control-zoom a.leaflet-disabled {
        border-color: @black-40;

        color: @black-40;
    }

    .leaflet-control-zoom a:first-child {
        margin-bottom: 5px;
    }

    .leaflet-control-zoom .leaflet-control-zoom-out span {
        display: inline-block;
        transform: translateX(-1px);
        font-size: 0.95em;
    }

    .rcg-transfer-icon {
        display: block;
        margin: 0;
    }

    .rcg-transfer-station-tooltip {
        z-index: 1;

        background: none;
        border: none;
        box-shadow: none;

        font-family: @font-default;

        &::before {
            content: none;
        }

        .rcg-transfer-location-marker-link {
            opacity: 0;
        }
    }

    .rcg-transfer-station-active-tooltip {
        z-index: 2;

        padding: 4px 10px;

        background-color: @oebb-red;
        border: none;

        color: @white;
        font-family: @font-default;
        font-size: 14px;
        text-transform: uppercase;

        span {
            display: inline-block;
            vertical-align: middle;
        }

        &.leaflet-tooltip-top::before {
            border-top-color: @oebb-red;
        }

        &.leaflet-tooltip-right::before {
            border-right-color: @oebb-red;
        }

        &.leaflet-tooltip-bottom::before {
            border-bottom-color: @oebb-red;
        }

        &.leaflet-tooltip-left::before {
            border-left-color: @oebb-red;
        }

        .rcg-transfer-location-marker-link {
            display: inline-block;
            vertical-align: middle;
            width: 32px;

            background-image: url('../../images/rcg-transfer/locations-white.svg');

            &:first-of-type {
                margin-left: 8px;
            }

            &--terminal {
                background-position: 4px 0;
            }

            &--habor {
                background-position: -30px 0;
            }

            &--station {
                background-position: -60px 0;
            }

            &--warehouse {
                background-position: -95px 0;
            }
        }
    }

    .rcg-transfer-station-active-tooltip--green {
        background-color: #87af28;

        // color: @black;

        &.leaflet-tooltip-top::before {
            border-top-color: #87af28;
        }

        &.leaflet-tooltip-right::before {
            border-right-color: #87af28;
        }

        &.leaflet-tooltip-bottom::before {
            border-bottom-color: #87af28;
        }

        &.leaflet-tooltip-left::before {
            border-left-color: #87af28;
        }

        // .rcg-transfer-location-marker-link {
        //     background-image: url('../images/rcg-transfer/locations.svg');
        // }
    }

    .marker-cluster-small,
    .marker-cluster-medium,
    .marker-cluster-large {
        background: rgba(225, 225, 225, 0.75);

        > div {
            background: rgba(255, 255, 255, 0.75);

        }
    }

    .rcg-transfer-reload {
        width: 30px;
        height: 30px;
        margin-top: 5px;
        padding: 0;

        background: #ffffff;
        border: 2px solid @oebb-red;
        border-radius: 50%;

        line-height: 30px;

        .rcg-transfer-icon {
            width: 22px;
            height: 22px;
            margin: 5px 0 0 5px;
        }
    }

    .leaflet-overlay-pane .leaflet-interactive {
        cursor: grab;
    }

    .rcg-transfer-loader {
        top: 45%;

        display: block;
        margin: 0;

        transform: translateY(-50%);

        &[hidden] {
            display: none;
        }

        &::after {
            background-color: #f7f7f7;
        }
    }
}

// .rcg-transfer-search-information {
//     display: none;
// }

.no-js .content {
    .rcg-transfer-map,
    .rcg-transfer__title,
    .rcg-transfer__form-togglers,
    .rcg-transfer__form-container--from,
    .rcg-transfer__form-container--to,
    .rcg-transfer__form-container--charge,
    .rcg-transfer__legend {
        display: none;
    }

    .rcg-transfer__form {
        position: static;
        float: right;

        display: block;
        width: 248px;
        padding: 0;

        @media @medium-devices-and-smaller {
            width: 100%;
        }
    }

    .rcg-transfer__form-container--transfer {
        position: static;
    }

    .rcg-transfer__content {
        width: ~'calc(100% - 248px)';
        margin-top: 0;

        @media @medium-devices-and-smaller {
            width: 100%;
        }
    }

    .rcg-transfer__dropdown-parent {
        background: @oebb-red;
        color: #fff;

        .ic-arrow2-down {
            display: none;
        }

        .ic-arrow2-right {
            display: none;
        }
    }

    .rcg-transfer__subdropdown {
        max-height: 325px;
        border-bottom: 1px solid @black-55;

        .rcg-tranfer-scrollbars();

        @media @medium-devices-and-smaller {
            max-height: none;
        }
    }
}
