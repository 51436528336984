.rcg.glossar {
	.container-top img {
		height: 50%;
	}
	.content {
		padding-top: 0;
	}

	.rcg-glossary-grid {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-template-areas: 'glossary-content glossary-nav';
		grid-gap: 20px;

		> .row {
			grid-area: glossary-content;
		}

		> .rcg-glossary-nav {
			grid-area: glossary-nav;
			align-self: start;
		}

		a {
			color: @oebb-red;
			text-decoration: underline;
		}
	}

	.rcg-glossary-nav {
		position: sticky;
		top: 60px;
		right: 0;

		width: 70px;
		height: ~'calc(100vh - 80px)';
		margin-top: 10px;
		margin-left: auto;

		background-color: @black-5;

		font-size: 20px;

		@media @screen-sm-max {
			width: 40px;

			font-size: 16px;
		}

		@media screen and (max-height: 900px) {
			width: 40px;

			font-size: 16px;
		}

		@media screen and (max-height: 580px) {
			width: 40px;

			font-size: 14px;
		}
	}

	.rcg-glossary-nav-list {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		height: 100%;
		margin: 0;
		padding: 10px 0;

		list-style: none;

		a {
			display: block;
			padding: 4px 12px;
			font-weight: bold;

			@media screen and (max-height: 900px) {
				padding-top: 2px;
				padding-bottom: 2px;
			}

			@media screen and (max-height: 650px) {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}

	section {
		position: relative;

		margin-top: -40px;
		padding-top: 40px;

		h2 {
			position: sticky;
			top: 40px;

			margin: 0;
			padding: 8px 0;

			background: @white;
			border-bottom: 1px solid @black-20;
		}

		.rcg-glossary-term {
			margin: 40px 0;

			h3 {
				margin-top: -85px;
				padding-top: 85px;
			}

			h4 {
				display: inline;

				&::after {
					content: ':';
				}
			}

			ul {
				display: inline;
				margin: 0;
				padding: 0;

				list-style: none;

				li {
					display: inline;

					&::after {
						content: ', ';
					}

					&:last-child::after {
						content: none;
					}
				}
			}

			&__description,
			&__references,
			&__synonyms,
			&__translations {
				margin-bottom: 1em;
			}
		}
	}
}
