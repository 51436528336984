.press-info-date {
	color: @black-55;
	font-weight: bold;
}
.press-author {
	margin-top: 2em;
}
.press-author img {
	display: block;
	position: relative;
	float: left;
	width: 70px;
	height: 70px;
	margin: 0;
}
.press-author-detail {
	display: block;
	margin-left: 100px;
	margin-bottom: 0;
}
.press-author-detail .ap-all-article {
	display: none;
}

.press-standard-text {
	margin-top: 2em;
}
.press-standard-text img {
	display: block;
	position: relative;
	float: left;
	width: 70px;
	height: 70px;
	margin: 0;
}
.press-standard-text-detail {
	display: block;
	margin-left: 100px;
	margin-bottom: 0;
}

.page-nav .press-pagenav-ap img {
	display: block;
	position: relative;
	float: left;
	width: 70px;
}
p.ap-name-link {
	display: block;
	margin-left: 80px;
	margin-bottom: 0;
	min-height: 70px;
}
p.ap-name-link * {
	display: block;
}
p.ap-name-link .ap-name {
	margin-bottom: 10px;
}
.ap-name {
	font-size: 16px;
	font-weight: bold;
}
p.ap-mail-link {
	margin-top: 0.5em;
}

p.ap-mail-link a, p.ap-mail a {
	white-space: nowrap;
}

@media @medium-devices-and-smaller{
	.press-author-detail .ap-all-article {
		display: block;
	}
}