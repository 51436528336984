/* Tabs */
.tab-container {
	overflow-x: hidden;
}

/* Smartphone-Version: Die Elemente werden als Klappbox dargestellt. */
ul.tab-handler {
	.clearfix();
	list-style-type: none;
	list-style: none;
	padding: 0;
	margin: 0;
	border-bottom: 1px solid @black-20;
	width: 3000px;

}

.content ul.tab-handler li {
	display: block;
	float: left;
	padding: 0;
	margin: 0;
	position: relative;
	z-index: 1;
}

ul.tab-handler > li > a {
	display: inline-block;
	position: relative;
	font-size: 14px;
	color: @dark-font;
	background: @black-5;
	padding: 4px 12px;
	margin-right: 2px;
	border: 1px solid @black-20;
	text-decoration: none;
	bottom: -1px;
}

ul.tab-handler > li > a:active, ul.tab-handler > li > a:focus, ul.tab-handler > li > a:hover {
	color: black;
	background: @black-20;
}

ul.tab-handler > li.active {

	margin-bottom: -1px;
	position: relative;
	z-index: 10;
}

ul.tab-handler > li.active a {
	background: white;
	border-bottom: 1px solid white;
	color: black;
}

ul.tab-handler > li .tab-headline {
	margin: 0;
	padding: 0;
	font-weight: normal;
}

/* Dropdown bei mehreren Elementen */

.content ul.tab-handler-dropdown {

	width: 210px;
	position: absolute;
	top: 0;
	left: 0;
	list-style-image: none;
	list-style-type: none;
	list-style: none;
	padding: 0;
	margin: 0;
	background: white;
	border: 1px solid @black-20;
	-webkit-box-shadow: 0px 1px 10px -2px rgba(0, 0, 0, 0.61);
	-moz-box-shadow: 0px 1px 10px -2px rgba(0, 0, 0, 0.61);
	box-shadow: 0px 1px 10px -2px rgba(0, 0, 0, 0.61);
	z-index: 5;
}

.content ul.tab-handler-dropdown > li {
	padding: 0;
	margin:0;
}

ul.tab-handler-dropdown .tab-headline {
	padding: 0;
	margin: 0;
	font-size: 14px;
}

ul.tab-handler-dropdown a {
	display: inline-block;
	color: @dark-font;
	text-decoration: none;
	width: 100%;
	padding: 8px 8px;
	border-bottom: 1px solid @black-20;
//	white-space: nowrap;
	font-size: 18px;
	font-family: "Frutiger Next Regular", "Source Sans Pro Regular", Arial;
}

ul.tab-handler-dropdown a:focus, ul.tab-handler-dropdown a:hover {
	background: @black-10;
}

ul.tab-handler-dropdown a:active {
	background: @black-5;
	color: @black;
}

ul.tabs-default {
	list-style-type: none;
	list-style: none;
	padding: 0;
	margin: 0;
}

ul.tabs-default > li {
	display: none;
	padding: 0;
	margin: 0;
	outline:0;
}

ul.tabs-default > li.open {
	display: block;
	outline:0;
}

/* Smartphone-Version: Die Elemente werden als Klappbox dargestellt. */
.content ul.tabs-as-accordeon {
	list-style-type: none;
	list-style: none;
	list-style-image:none;
	padding: 0;
	margin: 0;
}

.content ul.tabs-as-accordeon ul.accordeon {
	list-style-type: none;
	list-style: none;
	list-style-image:none;
	padding: 0;
	margin: 0;
}

ul.tabs-as-accordeon > li {
	display: block;
	padding: 0;
	margin: 0;
	border: 1px solid @black-10;
	margin-bottom: 2px;

}

ul.tabs-as-accordeon > li > a {
	padding: 8px 0;
	margin: 0;
	display: block;
	position: relative;
	width: 100%;
	padding-left: 32px;
	text-decoration: none;
	color: @dark-font;
	font-size: 15px;
	background: @black-5;

}

ul.tabs-as-accordeon > li > a:focus, ul.tabs-as-accordeon > li > a:hover {
	color: @oebb-red;
	background: @black-10;
}

ul.tabs-as-accordeon > li > a:active {
	color: black;

}

ul.tabs-as-accordeon > li > a > .tab-headline {
	padding: 0;
	margin: 0;
	font-size: 15px;
}

ul.tabs-as-accordeon > li > a span:not(.tab-headline) {
	display: block;
	position: absolute;
	top: 50%;
	margin-top: -10px;
	font-size: 20px;
	left: 8px;
	color: @oebb-red;
}

ul.tabs-as-accordeon > li > div {
	display: none;
	outline:0;
}

/*  Geöffnettes Accordeon-Element*/
ul.tabs-as-accordeon > li.open > div {
	display: block;
	margin-top: -8px;
	background: white;
	padding: 10px;
	outline:0;
}

ul.tabs-as-accordeon > li.open > a {
	border-bottom: 1px solid @black-10;
}

/*  Minus-Icon wird angezeigt.*/
ul.tabs-as-accordeon > li.open > a span:not(.tab-headline):before {
	content: "\e657";
}

.inpage-tab ul.tab-handler {
	display: none;
}

/* ************************ WEM TAB FAKE ***********************  */
div.tabs-start:before {
	display: block;
	font-family: 'oebb-fonticons';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	height: 20px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: "\e602 TABCONTAINER-START";
	background-color: @black-5;
	vertical-align: top;
	border-style: dashed;
	border-width: 1px;
	border-color: @dark-red transparent @dark-red @dark-red;
	white-space: nowrap;
}
div.tab-start:before {
	display: block;
	font-family: 'oebb-fonticons';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	height: 20px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: "\e602 TAB-ELEMENT";
	background-color: @black-5;
	vertical-align: top;
	border-style: dashed;
	border-width: 1px;
	border-color: @dark-red transparent @dark-red @dark-red;
	margin: 30px 0;
	white-space: nowrap;
}

div.tabs-end:before {
	display: block;
	font-family: 'oebb-fonticons';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	height: 20px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: "TABCONTAINER-END \e603";
	background-color: @black-5;
	vertical-align: top;
	border-style: dashed;
	border-width: 1px;
	border-color: @dark-red  @dark-red @dark-red transparent;
}

.content ul.tabs ul {
	list-style-image: url(@icon-name-bullet-point-primary);
}

.content ul.tabs ul ul {
	list-style-image: url(@icon-name-bullet-point-secondary);
}

.content ul.tabs ul ul ul {
	list-style-image: url("../../images/line-gray.svg");
}
